import {Link} from 'react-router-dom';

function PostSuccess({setPostSuccess, students}) {
    return (
        <div className="container m-auto flex items-center justify-center py-12">
            <div>
                <div className="flex flex-col items-center space-y-6 p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h1 className="text-4xl font-bold pb-2">Thank You !</h1>
                    <div className="text-center flex flex-col space-y-4 lg:text-lg">
                        <p>Thanks for sharing! You should receive an email notifying the couple to-be (bcc'ed, meaning they don't know you're involved).</p>
                        <p>You can track the match's progress on the Bulletin <Link to={`/?q=${students[0]} ${students[1]}`}><span className="text-blue-600 hover:underline dark:text-blue-500 cursor-pointer">here</span></Link>. The more upvotes they get, the more points you can earn (viewable on your Profile) if they end up following through. If they send us a selfie of each other together, we will notify you.</p>
                        <p>Meanwhile, feel free to browse the Bulletin, review your profile, or post another!</p>
                    </div>
                    <a onClick={() => {setPostSuccess(false)}}
                        className="cursor-pointer inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>
                        <span className="text-sm font-medium">
                            Post another
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default PostSuccess;