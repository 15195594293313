import React, { useState } from 'react';
import PostForm from './PostForm';
import PostSuccess from './PostSuccess';

function Post() {
    const [postSuccess, setPostSuccess] = useState(false);
    const [students, setStudents] = useState(["", ""]);

    return (
        <>
            {
                postSuccess
                ? <PostSuccess setPostSuccess={setPostSuccess} students={students}/>
                : <PostForm setPostSuccess={setPostSuccess} students={students} setStudents={setStudents}/>
            }
        </>
    );
}

export default Post;