import AuthLogo from "../images/venus-auth-logo.png";
import { Outlet, Link, useLocation } from "react-router-dom";
import React from 'react';

function Layout() { 
    const {pathname} = useLocation();

    return (
        <div>
            <div className="basis-1/4 p-4 flex flex-col md:flex-row justify-between" style={{background: "#FE9999", color: "white"}}>
                <Link to="/bulletin"><img src={AuthLogo} className="w-64 md:w-84 cursor-pointer" /></Link>
                <div className="flex flex-col md:flex-row">
                    <Link className="m-auto p-2 cursor-pointer hover:text-red-500" to="/bulletin" style={{color: (pathname === "/bulletin" || pathname === "/") ? "#A83200" : "white"}}>Bulletin</Link>
                    <Link className="m-auto p-2 cursor-pointer hover:text-red-500" to="/post" style={{color: (pathname === "/post") ? "#A83200" : "white"}}>Post</Link>
                    <Link className="m-auto p-2 cursor-pointer hover:text-red-500" to="/profile" style={{color: (pathname === "/profile") ? "#A83200" : "white"}}>Profile</Link>
                </div>
            </div>
            <Outlet />
        </div>
    );
}

export default Layout;