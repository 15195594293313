import React, { useEffect, useState } from "react";
import Landing from "./landing/Landing";
import AuthTemplate from "./auth-template/AuthTemplate";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./no-page/NoPage";
import Email from "./login/Email";
import Code from "./login/Code";
import Privacy from "./privacy/Privacy";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [emailed, setEmailed] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("isAuth") !== null) setIsAuthenticated(window.localStorage.getItem('isAuth'));
    if (window.localStorage.getItem("emailed") !== null) setEmailed(JSON.parse(window.localStorage.getItem('emailed')));
    setInitialRender(false);
  }, []);

  useEffect(() => {
   if (!initialRender) window.localStorage.setItem('isAuth', isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (!initialRender) window.localStorage.setItem('emailed', emailed);
  }, [emailed]);

  return (
    <>
      {
          isAuthenticated
          ? <AuthTemplate setAuth={setIsAuthenticated}/>
          : <BrowserRouter>
              <Routes>
                <Route index element={<Landing/>}/>
                <Route path="/email" element={<Email/>}/>
                <Route path="/code" element={<Code emailed={emailed} setAuth={setIsAuthenticated}/>}/>
                <Route path="/privacy" element={<Privacy />}/>
                <Route path="*" element={<NoPage/>}/>
              </Routes>
          </BrowserRouter>
        }
    </>
  );
}

export default App;
