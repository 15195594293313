import { useNavigate } from "react-router-dom";

function AuthNoPage() {
    const navigate = useNavigate();
    return (
        <div className="text-center">
            <div className="container m-auto p-8 text-center text-2xl">
                <p>Hm... The page you're trying to access does not exist.</p>
            </div>
            <button className="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-2/3 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => navigate("/")}>Go Home</button>
        </div>
    );
}

export default AuthNoPage;