import React from "react";
import Bulletin from "../bulletin/Bulletin";
import Post from "../post/Post";
import Profile from "../profile/Profile";
import Layout from "../layout/Layout";
import AuthPrivacy from "../privacy/AuthPrivacy";
import AuthNoPage from "../no-page/AuthNoPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function AuthTemplate({setAuth}) {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Bulletin/>}/>
                    <Route path="bulletin" element={<Bulletin/>}/>
                    <Route path="post" element={<Post/>}/>
                    <Route path="profile" element={<Profile setAuth={setAuth}/>}/>
                    <Route path="privacy" element={<AuthPrivacy/>}/>
                    <Route path="*" element={<AuthNoPage/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AuthTemplate;