import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

function PostForm({setPostSuccess, students, setStudents}) {
    const DOMAIN = "https://api.stanfordvenus.com";
    const [emails, setEmails] = useState(["", ""]);
    const [reason, setReason] = useState("");
    const [autoStudents, setAutoStudents] = useState([[], []]);
    const [displayErr, setDisplayErr] = useState(false);
    const token = window.localStorage.getItem('token');
    const navigate = useNavigate();

    const handleStudent1Change = (event) => {
        setStudents([event.target.value, students[1]]);
        axios.get(`${DOMAIN}/users/people/?q=${event.target.value}`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
        .then(resp => {
            setAutoStudents([JSON.parse(resp.data), autoStudents[1]]);
        })
        .catch(err => {
            setDisplayErr(true);
            console.log(err);
        });
    };

    const handleStudent2Change = (event) => {
        setStudents([students[0], event.target.value]);
        axios.get(`${DOMAIN}/users/people/?q=${event.target.value}`, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
        .then(resp => {
            setAutoStudents([autoStudents[0],JSON.parse(resp.data)]);
        })
        .catch(err => {
            setDisplayErr(true);
            console.log(err);
        });
    };

    const handleShare = () => {
        axios.post(`${DOMAIN}/matchmaker/match/`, {
            email_1: emails[0],
            email_2: emails[1],
            note: reason,
            school: 'Stanford',
        }, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
        .then(resp => {
            console.log(resp);
            setDisplayErr(false);
            if (resp.status === 200) {
                navigate("/?q=" + students[0] + " " + students[1]);
            } else if (resp.status === 201) {
                setPostSuccess(true);
            }
        })
        .catch(err => {
            setDisplayErr(true);
            console.log(err.response.data);
        })
    }

    useEffect(() => {
        let studentOne = document.querySelector("#student_one");
        let studentTwo = document.querySelector("#student_two");
        studentOne.value = students[0];
        studentTwo.value = students[1];
    }, [students]);
    
    

    return (
        <div className="container m-auto flex flex-col w-3/4 md:w-2/3 lg:w-1/2">
            <div className="basis-1/4 p-12">
                <p className="text-center text-3xl">Put it out there...</p>
            </div>
            { 
                displayErr && 
                <p className="text-red-500 text-center">Please select from the dropdowns.</p>
            }
            <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div className="relative">
                    <label htmlFor="student_one" className="block mb-2 text-sm font-medium text-gray-900">Student 1 <span className="text-red-500 text-md">*</span></label>
                    <input type="text" id="student_one" onChange={handleStudent1Change} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Start typing their name or suid..." required/>
                    { autoStudents[0].length > 0 &&
                        <ul className="bg-gray-100 rounded-md absolute p-2.5 w-full z-50">
                            {autoStudents[0].map((person, i) => {
                                return (
                                    (i === 0) 
                                        ? <li key={`item${i}`} onClick={() => {setStudents([person.first_name + " " + person.last_name,students[1]]);setAutoStudents([[],autoStudents[1]]);setEmails([person.email,emails[1]])}} className="w-full text-gray-700 p-3 bg-white hover:bg-gray-200 text-sm rounded-md cursor-pointer" >{person.first_name} {person.last_name} ({person.email})</li> 
                                        : <li key={`item${i}`} onClick={() => {setStudents([person.first_name + " " + person.last_name,students[1]]);setAutoStudents([[],autoStudents[1]]);setEmails([person.email,emails[1]])}} className="w-full text-gray-700 p-3 mt-1 bg-white hover:bg-gray-200 text-sm rounded-md cursor-pointer">{person.first_name} {person.last_name} ({person.email})</li>
                                );
                            })}
                        </ul>
                    }
                </div>
                <div className="relative">
                    <label htmlFor="student_two" className="block mb-2 text-sm font-medium text-gray-900">Student 2 <span className="text-red-500 text-md">*</span></label>
                    <input onChange={handleStudent2Change} type="text" id="student_two" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Start typing their name or suid..." required/>
                    { autoStudents[1].length > 0 &&
                        <ul className="bg-gray-100 rounded-md absolute p-2.5 w-full">
                            {autoStudents[1].map((person, i) => {
                                return (
                                    (i === 0) 
                                        ? <li key={`item${i}`} onClick={() => {setStudents([students[0],person.first_name + " " + person.last_name]);setAutoStudents([autoStudents[0],[]]);setEmails([emails[0],person.email])}} className="w-full text-gray-700 p-3 bg-white hover:bg-gray-200 text-sm rounded-md cursor-pointer" >{person.first_name} {person.last_name} ({person.email})</li> 
                                        : <li key={`item${i}`} onClick={() => {setStudents([students[0],person.first_name + " " + person.last_name]);setAutoStudents([autoStudents[0],[]]);setEmails([emails[0],person.email])}} className="w-full text-gray-700 p-3 mt-1 bg-white hover:bg-gray-200 text-sm rounded-md cursor-pointer">{person.first_name} {person.last_name} ({person.email})</li>
                                );
                            })}
                        </ul>
                    }
                </div>
            </div>
            <div className="basis-1/3 flex flex-col">
                <label htmlFor="reason" className="block mb-2 text-sm font-medium text-gray-900">Why should they get together?</label>
                <textarea id="reason" rows="4" onChange={(event) => setReason(event.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="(optional)"></textarea>
            </div>
            <div className="basis-1/6 grid place-items-center py-4">
                <button onClick={handleShare} className="mt-2 align-left text-white bg-rose-700 hover:bg-rose-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-rose-600 dark:hover:bg-rose-700 dark:focus:ring-rose-800">Share</button>
            </div>
        </div>
    );
}

export default PostForm;